// NODE MODULES
import React from 'react';
import Layout from "../layouts/pl";
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Fade from 'react-reveal/Fade';

// COMPONENTS
import PhotoGallery from '../components/Gallery';


const OfertaSpecjalna = (props) => {

  const data = useStaticQuery(graphql` 
    query {
        desktopImage: file(relativePath: { eq: "images/photos/oferta/ofertaSpecjalna-new11.jpg" }) {
            childImageSharp {
                fluid(quality: 100){
                ...GatsbyImageSharpFluid
                }
            }   
        }
        mobileImage: file(relativePath: { eq: "images/photos/oferta/ofertaSpecjalna-new11-mobile.jpg" }) {
            childImageSharp {
                fluid(quality: 50){
                ...GatsbyImageSharpFluid
                }
            }   
        }
    }
  `);

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: `(min-width: 768px)`,
    },
  ];

  return (
    <Layout location={props.location}>
      <Fade up>
        <section className="area" id="section2">
          <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
          <h1 className="title">SPECIAL OFFER</h1>
          <div className="content-container">
            <p>We invite you to spend your days off, public holidays or ony other special occasions in our AMBER APARTMENTS. </p>
            <p>We take special care so that you can feel like home during the stay in our apartments. </p>
            <p>You can order breakfast, Christmas dinner, even New Year's snacks, or any other dishes which will be delivered to the doorstep of the apartment. </p>
            <p>You can also buy a gift - a voucher for a stay in our apartments for your friends or family members. </p>
          </div>
        </section>
      </Fade>
      <Fade up>
        <section className="photos" id="section3">
          <h1>Gallery</h1>
          <div className="photos">
            <PhotoGallery galleryType='ofertySpecjalne' />
          </div>
        </section>
      </Fade>
    </Layout>
  )
}

export default OfertaSpecjalna
